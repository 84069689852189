import { useNavigate } from "react-router-dom";
import GridContainer from "./commonComponents/GridContainer";
import Tile from "./commonComponents/Tile";
import notFoundImage from "./utils/images/noImage.jpeg";
import { homepageData } from "./utils/homepageData";
import { events_Data } from "./utils/eventData";
import {
  ADDRESS,
  CONTACT_US,
  EMAIL,
  EMAIL_STRING,
  READ_MORE,
} from "./utils/constants";

const HomePageBanner = () => {
  return <div id={1} className="homepageBanner"></div>;
};

const HorizontalSeperator = () => {
  return <div className="w-full py-2 md:py-4"></div>;
};

const EventsTile = ({ language }) => {
  const navigate = useNavigate();

  const handleCarouselClick = (e) => {
    navigate(`/details/${e?.id}`);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-6">
      {events_Data.map((data, index) => {
        return (
          <div
            key={`events-${index}`}
            className="bg-white cursor-pointer transition duration-500 hover:scale-110 rounded-md shadow-xl flex flex-col"
            onClick={(e) => handleCarouselClick(data)}
          >
            <div className="w-full object-contain">
              <img
                src={data?.images?.[0] ? data?.images[0] : notFoundImage}
                alt={"event"}
              />
            </div>
            <div className="text-base text-black-light font-semibold px-4 md:px-2.5 pt-4 line-clamp">
              {data?.title?.[language]}
            </div>
            <div className="text-sm text-red-900 font-semibold p-4 md:p-2.5 text-right w-full">
              {READ_MORE?.[language]} {"..."}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ContactUsTile = ({ language }) => {
  return (
    <div className="commonTile" id={4}>
      <div className="commonTile_header">{CONTACT_US?.name?.[language]}</div>
      <div className="bg-red-900 h-px my-2"></div>
      <div className="commonTile_description mt-4">
        <div>
          <span className="font-semibold">{ADDRESS?.title?.[language]}</span> :{" "}
          <span>{ADDRESS?.info?.[language]}</span>
        </div>
        <div>
          <span className="font-semibold">{EMAIL_STRING?.[language]}</span> :{" "}
          {EMAIL}
        </div>
      </div>
    </div>
  );
};

const Homepage = ({ language }) => {
  return (
    <div className="col-span-12">
      <HomePageBanner />
      <HorizontalSeperator />
      <GridContainer>
        <div className="col-span-12 mt-2">
          {Object.keys(homepageData).map((data) => {
            return (
              <div id={homepageData?.[data]?.id} key={homepageData?.[data]?.id}>
                <Tile 
                  header={homepageData?.[data]?.header?.[language]}
                  description={homepageData?.[data]?.description?.[language]}
                />
              </div>
            );
          })}
          <div>
            <EventsTile language={language} />
          </div>
          <HorizontalSeperator />
          <ContactUsTile language={language} />
        </div>
      </GridContainer>
    </div>
  );
};

export default Homepage;
