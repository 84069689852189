import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import GridContainer from "./commonComponents/GridContainer";
import { events_Data } from "./utils/eventData";
import notFoundImage from "./utils/images/noImage.jpeg";

const DetailsPage = ({ language }) => {
  const { id } = useParams();
  const [event, setEvent] = useState({});

  const fetchEventDetails = () => {
    let eventDetails = events_Data.filter((event) => {
      return event?.id === parseInt(id);
    });

    if (eventDetails?.length > 0) {
      setEvent(eventDetails[0]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchEventDetails(); // eslint-disable-next-line
  }, []);

  return (
    <>
      <GridContainer>
        {Object.keys(event).length > 0 ? (
          <div className="col-span-12">
            <div className="commonTile_header my-4 text-center pt-2">
              {event?.title?.[language]}
            </div>
            <div className="flex justify-center">
              <img src={event?.images[0]} alt="event" />
            </div>
            <div className="commonTile_description pt-4 my-2">
              {event?.description?.[language]
                ? parse(event?.description?.[language])
                : ""}
            </div>
            <div>
              {event?.images?.length > 0 && (
                <div className="grid grid-cols-12 m-auto pt-6">
                  <div className="col-span-12 lg:pb-8 md:block">
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 min-h-px overflow-auto py-1">
                      {event?.images.map((item, index) => {
                        return (
                          <div key={index}>
                            <img
                              src={item ? item : notFoundImage}
                              alt="event"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>No such events</div>
        )}
      </GridContainer>
    </>
  );
};

export default DetailsPage;
