const scrollToContainer = (id) => {
  const element = document.getElementById(id)
  const offset = window && window.innerWidth < 768 ? 190 : 145 //desktop , 108 - md smaller
  const bodyRect = document.body.getBoundingClientRect().top || 0
  const elementRect = element.getBoundingClientRect().top || 0
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset
  return window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

export default scrollToContainer
