import {useEffect, useState} from 'react'
import ReactGA from 'react-ga';
import './App.css'
import Homepage from './Homepage'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import DetailsPage from './DetailsPage'
import Header from './Header'
import { DEFAULT_LANGUAGE, ENG_LANG, KAN_LANG } from './utils/constants'

function App() {

  useEffect(()=> {
    ReactGA.initialize('UA-234103961-1');
    ReactGA.pageview(window.location.pathname + window.location.search)
  },[])

  const [language, setLanguage]= useState(DEFAULT_LANGUAGE)

  const handleLanguageChange= () => {
    setLanguage(language === KAN_LANG ? ENG_LANG : KAN_LANG)
  }

  return (
    <BrowserRouter>
      <div className="App">
        <Header language={language} handleLanguageChange={handleLanguageChange}/>
        <div className="relative min-h-min_foot">
          <Routes>
            <Route exact path="/" element={<Homepage  language={language} />} />
            <Route exact path="/details/:id" element={<DetailsPage  language={language} />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App
