import parse from "html-react-parser";

const Tile = ({ header, description }) => {
  return (
    <div className="commonTile">
      <div className="commonTile_header">{header}</div>
      <div className="bg-red-900 h-px my-2"></div>
      <div className="commonTile_description">
        {description ? parse(description) : ""}
      </div>
    </div>
  );
};

export default Tile;
