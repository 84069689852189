import GridContainer from "./commonComponents/GridContainer";
import scrollToContainer from "./commonComponents/useScroll";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import logo from "./utils/images/logo.png";
import {
  DEFAULT_LANGUAGE,
  HEADER_OPTIONS,
  LANGUAGE_ENGLISH,
  LANGUAGE_KANNADA,
  LOGO_HEADER_1,
  LOGO_HEADER_2,
} from "./utils/constants";

const LanguageSwitch = ({ language, handleLanguageChange }) => {
  return (
    <div className="flex flex-row gap-2 md:justify-between md:items-start justify-end">
      <div>{LANGUAGE_ENGLISH}</div>
      <Switch
        onChange={handleLanguageChange}
        checked={language === DEFAULT_LANGUAGE}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={20}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={40}
        uncheckedIcon={false}
        checkedIcon={false}
        className="react-switch"
        id="material-switch"
      />
      <div>{LANGUAGE_KANNADA}</div>
    </div>
  );
};

const Header = ({ language, handleLanguageChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname === "/";

  const handleTabChange = (e) => {
    if (location.pathname === "/") {
      if (e === 1) {
        navigate(`/`);
        return;
      }
      scrollToContainer(e);
    } else {
      navigate(`/`);
    }
  };

  return (
    <div className="bg-white sticky top-0 inset-x-0 z-50 shadow-bottom_grey">
      <GridContainer>
        <div className="col-span-12 flex flex-wrap flex-col py-4">
          <div className="flex flex-row items-center">
            <div className="text-4xl text-red-900 font-bold">
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="h-16 w-full object-contain"
                />
              </Link>
            </div>
            <div className="md:pl-4 pt-3 text-2xl font-bold hidden md:block">
              {LOGO_HEADER_1?.[language]} {LOGO_HEADER_2?.[language]}
            </div>
            <div className="md:pl-4 pt-3 text-2xl font-bold block md:hidden text-center pl-2">
              <div>{LOGO_HEADER_1?.[language]}</div>
              <div>{LOGO_HEADER_2?.[language]}</div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-2 text-black-light text-sm md:text-base font-semibold cursor-pointer pt-4">
            <div className="flex flex-row flex-wrap gap-x-5 gap-y-2">
              {" "}
              {pathName ? (
                HEADER_OPTIONS.map((option) => {
                  return (
                    <div
                      key={`header-${option?.id}`}
                      onClick={() => handleTabChange(option?.id)}
                    >
                      {option?.name?.[language]}
                    </div>
                  );
                })
              ) : (
                <div key={`header-1}`} onClick={() => navigate("/")}>
                  {HEADER_OPTIONS[0]?.name?.[language]}
                </div>
              )}
            </div>
            <div>
              <LanguageSwitch
                language={language}
                handleLanguageChange={handleLanguageChange}
              />
            </div>
          </div>
        </div>
      </GridContainer>
    </div>
  );
};

export default Header;
