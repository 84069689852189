const GridContainer = ({ children, bgColor }) => {
  return (
    <div
      className={`w-full min-height-inherit ${bgColor ? bgColor : 'bg-white'}`}
    >
      <div
        className={'grid grid-cols-12 m-auto lg:gap-15 px-8 lg:px-24 max-w-bl'}
      >
        {children}
      </div>
    </div>
  )
}

export default GridContainer
