export const HOMEPAGE_HEADER =
  "WELCOME TO NIREL CHARITABLE TRUST(R) WHERE YOU CAN ACCESS THE PROJECTS AND ACHIEVEMENTS OF NIREL,MANGALORE.";

export const DEFAULT_LANGUAGE = "kn";
export const ENG_LANG = "en";
export const KAN_LANG = "kn";

export const LANGUAGE_KANNADA = "ಕನ್ನಡ";
export const LANGUAGE_ENGLISH = "English";
export const EMAIL = "ngyuvavedike@gmail.com";

export const HEADER_OPTIONS = [
  { id: 1, name: { en: "HOME", kn: "ಮುಖಪುಟ" } },
  { id: 2, name: { en: "ABOUT US", kn: "ನಮ್ಮ ಬಗ್ಗೆ" } },
  { id: 3, name: { en: "EVENTS", kn: "ಕಾರ್ಯಕ್ರಮಗಳು" } },
  { id: 4, name: { en: "CONTACT US", kn: "ನಮ್ಮನ್ನು  ಸಂಪರ್ಕಿಸಿ" } },
];

export const CONTACT_US = {
  id: 4,
  name: { en: "CONTACT US", kn: "ನಮ್ಮನ್ನು  ಸಂಪರ್ಕಿಸಿ" },
  description: {
    en: "",
    kn: "",
  },
};

export const LOGO_HEADER_1 = {
  en: "Narayana Guru",
  kn: "ನಾರಾಯಣ ಗುರು",
};

export const LOGO_HEADER_2 = {
  en: "Yuva Vedike[R]",
  kn: "ಯುವ ವೇದಿಕೆ[ರಿ.]",
};

export const EMAIL_STRING = {
  en: "E-mail",
  kn: "ಇ-ಮೇಲ್",
};

export const ADDRESS = {
  title: {
    en: "Address",
    kn: "ವಿಳಾಸ",
  },
  info: {
    en: "No.45 Kerala Samaja Building, K.S.Rao Road, Mangaluru - 575001",
    kn: "ನಂ.45, ಕೇರಳ ಸಮಾಜ ಬಿಲ್ಡಿಂಗ್, ಕೆ.ಎಸ್.ರಾವ್ ರಸ್ತೆ, ಮಂಗಳೂರು - 575001",
  },
};

export const READ_MORE = {
  en: "Read more",
  kn: "ಹೆಚ್ಚಿನ ವಿವರ",
};

export const SCHOLARSHIP_FORM = {
  header: {
    en: "Brahmashree Bangarada Padaka and Scholarship Application Form",
    kn: "ಬ್ರಹ್ಮಶ್ರೀ ಬಂಗಾರದ ಪದಕ ಹಾಗು ವಿದ್ಯಾರ್ಥಿವೇತನಕ್ಕೆ ಅರ್ಜಿ ",
  },
  notice: {
    en: "Last date for submitting application is 28th June 2024",
    kn: "ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಕೊನೆಯ ದಿನಾಂಕ 28 ಜೂನ್ 2024.",
  },
  link_label:{
    en: 'Click here to apply',
    kn: 'ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ'
  },
  scholarships: [
    {
      name: {
        en: "SSLC",
        kn: "ಏಸ್.ಏಸ್.ಎಲ್.ಸಿ",
      },
      link: "https://docs.google.com/forms/d/e/1FAIpQLSeLGgd1GY3gsrfXCCp_EHTBGCuGzRRVneIV9fGFpwW5ZJZhow/viewform",
    },
    {
      name: {
        en: "PUC",
        kn: "ಪಿ.ಯು.ಸಿ",
      },
      link: "https://docs.google.com/forms/d/e/1FAIpQLSdu4lhBDZBI-pIMYOfb6kSybNFthh4yRhvuhacwisyPnhns-g/viewform",
    },
  ],
};
